<template>
  <div class="loading" v-if="isLoading">loading……</div>
  <empty v-else-if="!isLoading && !listLength"/>
  <div v-else></div>
</template>
<script>
  import Empty from './Empty';

  export default {
    name: 'LoadingBar',
    props: {
      isLoading: Boolean,
      listLength: Number
    },
    components: {Empty}
  };
</script>
<style scoped lang="less">
  .loading{padding:30px;text-align:center;}
</style>

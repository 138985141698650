<template>
  <div class="classification" v-if="classificationOptions.length">
    <ul>
      <li :class="{active:classificationCode===''}" @click="onSelect('')">
        <a href="javascript:;">全部</a>
      </li>
      <li v-for="item of classificationOptions"
          :class="{active:item.code===classificationCode}"
          :key="item.id"
          @click="onSelect(item.code)">
        <a href="javascript:;">{{item.name}}</a>
      </li>
    </ul>
  </div>
</template>
<script>
  import {getDictList} from '../../../api/common';

  export default {
    name: 'Classification',
    data() {
      return {
        index: 1,
        classificationOptions: [],
        classificationCode: ''
      };
    },
    created() {
      getDictList('philosopherClassification').then(res => {
        const {value} = res;
        this.classificationOptions = value;
      });
    },
    methods: {
      onSelect(classificationCode) {
        const value = classificationCode === this.classificationCode ? '' : classificationCode;
        this.classificationCode = value;
        this.$emit('input', value);
        this.$emit('change', value);
      }
    }
  };
</script>
<style scoped lang="less">
  .classification{
    height:50px;margin-bottom:24px;border-bottom:1px dashed #ff6f61;
    ul{
      li{
        display:inline-block;position:relative;margin-right:30px;vertical-align:top;text-align:left;
        &.active{
          &:after{position:absolute;right:0;bottom:0;left:0;height:4px;background-color:#ff6f61;content:'';}
        }
      }
      a{display:inline-block;vertical-align:top;line-height:50px;color:#321908;}
    }
  }
</style>

<template>
  <div class="fr-group" v-if="list.length">
    <div class="hd">{{$t('FeaturedTravelNotes')}}</div>
    <div class="bd">
      <router-link class="item-big ani-scale" target="_blank"
                   v-for="item of bigList"
                   :to="{name:'TravelsDetail',params:{id:item.travelsId}}"
                   :key="item.travelsId">
        <img class="img" :src="item.coverUrl" alt=""/>
        <div class="title">{{item.title}}</div>
      </router-link>
      <router-link class="item cl" target="_blank"
                   v-for="item of smallList"
                   :to="{name:'TravelsDetail',params:{id:item.travelsId}}"
                   :key="item.travelsId">
        <div class="cover">
          <img class="img" :src="item.coverUrl" alt=""/>
        </div>
        <div class="title">
          <span>{{item.title}}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Featured',
    props: {
      list: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      bigList: function () {
        let list = [...this.list];
        return list.splice(0, 1);
      },
      smallList: function () {
        let list = [...this.list];
        return list.splice(1);
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../common";
  .bd{padding:5px;}
  .item-big{
    display:block;position:relative;height:200px;margin-bottom:5px;border-radius:4px;overflow:hidden;
    .title{position:absolute;right:0;bottom:0;left:0;padding:4px 10px;text-align:center;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;color:#fff;font-size:18px;background-color:rgba(0,0,0,.5);}
  }
  .item{
    display:block;padding:5px 0;border-top:1px solid #f4f4f4;
    .cover{float:right;width:50px;height:50px;}
    .title{
      display:flex;align-items:center;height:50px;margin-right:100px;
      > span{display:-webkit-box;max-height:44px;line-height:22px;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
    }
  }
</style>

<template>
  <div class="fr-group" v-if="list.length">
    <div class="bd">
      <div class="hd">
        <router-link target="_blank" :to="{name:'TravelerList'}">{{$t('Traveler')}}</router-link>
      </div>
      <div class="list cl">
        <router-link class="traveler-item"
                     target="_blank"
                     v-for="item of list"
                     :to="{name:'TravelerDetail',params:{id:item.philosopherId}}"
                     :key="item.travelsId">
          <el-tooltip class="item" effect="dark" :content="item.penName" placement="bottom">
            <avatar :url="item.photoUrl" :is-self="false"/>
          </el-tooltip>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import Avatar from '../../../components/Avatar';
  export default {
    name: 'Traveler',
    components: {Avatar},
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../common";
  .list{padding-left:20px;}
  .traveler-item{
    float:left;width:48px;height:48px;margin:0 26px 20px 0;overflow:hidden;border-radius:50%;
    &:nth-child(4n){margin-right:0;}
    /deep/ .avatar{width:48px;height:48px;}
  }
</style>

<template>
  <div class="fr-group">
    <div class="bd">
      <div class="hd">标题</div>
      <div class="cont">
        从 2015 年 4 月起，Ant Design 在蚂蚁金服中后台产品线迅速推广，对接多条业务线，覆盖系统 800 个以上。定位于中台业务的 Ant Design 兼顾专业和非专业的设计人员，具有学习成本低、上手速度快、实现效果好等特点。
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'News'
  };
</script>
<style scoped lang="less">
  @import "../common";
  .fr-group{
    margin-top:30px;
    .hd{padding:20px 0;text-align:left;}
    .bd{padding:0 20px;}
    .cont{padding-bottom:20px;text-align:justify;}
  }
</style>

<template>
  <router-link class="travels-item ani-scale" target="_blank" :to="{name:'TravelsDetail',params:{id:datas.travelsId}}">
    <div class="cover">
      <img class="img" :src="datas.coverUrl" alt=""/>
    </div>
    <div class="infos">
      <div class="title" :title="datas.title">{{datas.title}}</div>
      <div class="cl">
        <!--<div class="fl">
          <i class="icons icon-place"/>
          <span class="place-name">香港</span>
        </div>-->
        <div class="fr fc9">{{datas.updateDate|formatDate('yyyy年MM月dd日')}}</div>
      </div>
    </div>
  </router-link>
</template>
<script>
  export default {
    name: 'TravelsItem',
    props: {
      datas: {
        type: Object,
        default: () => ({})
      }
    }
  };
</script>
<style scoped lang="less">
  .travels-item{
    float:left;width:300px;margin:0 20px 20px 0;overflow:hidden;border-radius:4px;box-shadow:0 2px 8px 0 rgba(255, 115, 101, 0.2);
    .cover{height:170px;overflow:hidden;}
    .infos{min-height:120px;padding:16px;}
    .title{display:-webkit-box;height:50px;margin-bottom:12px;line-height:25px;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;font-size:16px;}
    .place-name{display:inline-block;vertical-align:middle;color:#999;}
  }
  .icon-place{width:16px;height:16px;vertical-align:middle;background-image:url(../../../assets/images/icon/place.png);}
</style>

<template>
  <div>
    <banner :list="banners"/>
    <div class="layout">
      <div class="main cl">
        <div class="fr">
          <traveler :list="philosophers"/>
          <featured :list="featuredTravels"/>
          <hot :list="popularTravels"/>
          <news v-if="false"/>
        </div>
        <div class="fl">
          <classification v-model="classificationId" @change="changeCategory"/>
          <div class="cl" v-if="list&&list.length">
            <travels-item v-for="item of list" :datas="item" :key="item.travelsId"/>
          </div>
          <loading-bar :is-loading="loading" :list-length="list.length"/>
          <el-pagination class="pages"
                         background
                         layout="prev, pager, next"
                         :page-count="totalPages"
                         @current-change="changePage"
                         hide-on-single-page>
          </el-pagination>
        </div>
        <div class="gap-line"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import LoadingBar from '../../components/LoadingBar';
  import Classification from './components/Classification';
  import Banner from './components/Banner';
  import Traveler from './components/Traveler';
  import Featured from './components/Featured';
  import Hot from './components/Hot';
  import News from './components/News';
  import TravelsItem from './components/TravelsItem';
  import saveScrollPosition from '../../mixins/saveScrollPosition';
  import {getTravelsHomeCommon, getTravelsHomeList} from '../../api/travels';

  export default {
    name: 'Travels',
    data() {
      return {
        classificationId: '',
        banners: [],
        philosophers: [],
        featuredTravels: [],
        popularTravels: [],
        list: [],
        totalPages: 0,
        loading: true
      };
    },
    components: {LoadingBar, Classification, Banner, Traveler, Featured, Hot, News, TravelsItem},
    mixins: [saveScrollPosition],
    created() {
      this.getCommonData();
      this.getList();
    },
    methods: {
      // 获取公司数据
      getCommonData() {
        getTravelsHomeCommon().then(res => {
          const {value} = res;
          this.banners = value.banners || [];
          this.philosophers = value.philosophers || [];
          this.featuredTravels = value.featuredTravels || [];
          this.popularTravels = value.popularTravels || [];
        });
      },
      // 获取游记列表
      getList(page = 1) {
        const data = {page, perPage: 10, classificationId: this.classificationId};
        this.loading = true;
        getTravelsHomeList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.loading = false;
        });
      },
      changeCategory(classificationId) {
        this.classificationId = classificationId;
        this.getList();
      },
      changePage(page) {
        this.getList(page);
      }
    }
  };
</script>
<style scoped lang="less">
  @import "common";
  .main{
    position:relative;margin-top:30px;
    > .fl{float:none;margin-right:320px;}
    > .fr{width:320px;padding-left:10px;}
    .gap-line{position:absolute;top:0;right:320px;width:1px;height:100%;background-color:#f4f4f4;}
  }
</style>
